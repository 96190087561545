import React, { useState } from 'react';
import { Button, Checkbox, Col, Row, Typography } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import s from './styles.module.scss'

const CheckboxGroup = Checkbox.Group;
const { Title } = Typography;



interface Settings {
  allChecks: {
    checkAll: boolean,
    checkedAll: string[],
    indeterminate: boolean
  },
  onChangeCheckAll: (e: CheckboxChangeEvent) => void,
  setCheckbox: (checkedList: CheckboxValueType[]) => void,
  Checkboxs: string[],
  TableColumn: any
}


const TableSettings: React.FC<Settings> = ({ allChecks, onChangeCheckAll, setCheckbox, Checkboxs, TableColumn }) => {

  const [isOpen, setOpen] = useState(false);
  const toggleOpen = () => setOpen(!isOpen);
  let nameCheckList: string[] = [];
  let nameField = '';

  TableColumn().forEach((e) => {
    const traverseColumns = (column) => {
      if (column.title) {
        nameCheckList.push(column.title);
      }
  
      if (column.children) {
        column.children.forEach((child) => {
          traverseColumns(child);
        });
      }
    };
  
    traverseColumns(e);
  });

  // console.log('nameCheckList', nameCheckList)

  return (
    <>
      {!isOpen && (
        <Button type="link" onClick={() => toggleOpen()} style={{ padding: 0 }}>
          Open table settings
        </Button>
      )}
      {isOpen && (
        <>
          <Button type="link" onClick={() => toggleOpen()} style={{ padding: 0 }}>
            Close table settings
          </Button>
          <Title level={3}>Select Columns</Title>
          <Row className="mb">
            <Checkbox indeterminate={allChecks.indeterminate} onChange={onChangeCheckAll} checked={allChecks.checkAll}>
              Select All
            </Checkbox>
          </Row>
          <Row>
            <CheckboxGroup value={Checkboxs} onChange={(e) => setCheckbox(e)} className={s.checksBoxGroup}>
              {nameCheckList.map((item, key) => (
                // eslint-disable-next-line react/no-array-index-key
                <Col
                  key={key}
                  className={s.small}
                  // style={{ width: '100%', height: 20, marginRight: 20, maxWidth: "fit-content", }}
                  span={4}
                >
                  <Checkbox value={item}>{item}</Checkbox>
                </Col>
              ))}
            </CheckboxGroup>
          </Row>
        </>
      )}
    </>
  );
}

export default TableSettings;
