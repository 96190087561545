import React from 'react';
import { Button, Tooltip } from 'antd'
import ShoppingCartOutlined from '@ant-design/icons/lib/icons/ShoppingCartOutlined';
//@ts-ignore
import OrderKeysModal from '../OrderKeysModal';
import useBoolean from '../../../Utils/useBoolean';

interface OrderKeysButton {
  pltId: number,
  pltProductId: number,
  region: string,
  pltName: string
}

const OrderKeysButton: React.FC<OrderKeysButton> = ({ pltId, pltProductId, region, pltName }) => {

  const { value: visible, setTrue: open, setFalse: close } = useBoolean(false);

  const handleOnClick = () => {
    // dispatch(getQuantityOnStock(pltId, productId, region), [])
    //   .then((res) => setQuantityOnStock(res.value.qty))
    open() 
  }

  const onOk = () => {
    close()
  }

  return (
    <>
      <Button
        type="primary"
        size="small"
        icon={<ShoppingCartOutlined />}
        onClick={handleOnClick}
        // disabled={!productId}
        // loading={orderGamesLoading}
      />
      <OrderKeysModal
        onOk={onOk}
        close={close}
        visible={visible}
        pltId={pltId}
        pltProductId={pltProductId}
        region={region}
        pltName={pltName}
      />
      </>
  )
}

export default OrderKeysButton
