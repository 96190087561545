import PropTypes from 'prop-types';
import React from 'react';
import PrintPercent from '../../../Components/Print/PrintPercent';

type ColumnCurrentProfit = {
  profit: number,
};

function ColumnCurrentProfit({ profit }: ColumnCurrentProfit) {
  return <PrintPercent percent={Math.round((profit * 100))} />;
}


export default ColumnCurrentProfit;
