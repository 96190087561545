import React, { useCallback, useEffect, useState } from 'react';
import s from './styles.module.scss'
import PopupWithJson from '../../../Components/PopupWithJson'
import { message } from 'antd';
import { useDispatch } from 'react-redux';
import { copyToClipboard } from '../../../Utils/textTools';
import CopyOutlined from '@ant-design/icons/lib/icons/CopyOutlined';
import EditOutlined from '@ant-design/icons/lib/icons/EditOutlined';
import EditPlatformProductId from '../../../Components/EditPlatformProductId';


interface ColumnName {
  afterUpdate: any,
  name: string,
  id: string,
  link: string,
  theme: any,
  content: any,
  mktRows: any
};

const ColumnName: React.FC<ColumnName> = ({ afterUpdate, name, id, link, theme, content, mktRows }) => {

  const dispatch = useDispatch()
  
  const handleClick = useCallback(() => {
    copyToClipboard(id);
    message.success('Steam ID has been copied!');
    // eslint-disable-next-line
  }, [id]);


  if (!name && !id) return <div className={s.none}>none</div>;


  return (
    <>
    {/* <br /> */}
    <div className={s.withTwoLine}>
      <a target="_blank" rel="noopener noreferrer" href={link} className={s.linkToMarket}>
        {name}
      </a>
      <br />
      <div className={s.idRow}>
        {id}
          <EditPlatformProductId mktRows={mktRows} afterUpdate={afterUpdate}/>
          <CopyOutlined style={{fontSize: '10px', marginLeft: '2px', width: '10px'}} onClick={handleClick} />
          <PopupWithJson title="..." content={content} />
      </div>
      </div>
      
    </>
  );
}

export default ColumnName;
