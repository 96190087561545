const list = ['xbox', 'steam', 'live', 'key', 'europe', 'united', 'states', 'one', 'north', 'america', 'standard', 'edition'];

export default function clearG2AName(str) {
  const arr = str.split(' ');
  const newArr = [];

  for (let i = 0; i < arr.length; ++i) {
    const item = arr[i];
    const itemLowerCase = item.toLowerCase();
    const nextItemLowerCase = (i + 1 < arr.length ? arr[i + 1] : item).toLowerCase();

    if (
      itemLowerCase === 'windows' &&
      (nextItemLowerCase === '10' || nextItemLowerCase === '7' || nextItemLowerCase === '8')
    ) {
      ++i;
    } else if (item && !list.includes(item.toLowerCase())) {
      newArr.push(item);
    }
  }

  return newArr.join(' ').trim();
}
