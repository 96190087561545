import React from 'react';
import { Tag } from 'antd';


interface PrintPriceRatio {
  price?: number,
  className?: string,
  isDefault?: boolean
};

function getColor (price: number): string | null {
  if (+price >= 40) {
    return '#e25757';
  }

  if (+price < 40) {
    return 'rgb(53, 179, 53)';
  }

  return '#28323f';
}

const PrintPriceRatio: React.FC<PrintPriceRatio> = ({ price, className, isDefault }) => {
  if (!price || price === null) {
    return <span className={className}>default</span>;
  }

  
  return (
    <Tag 
    style={{ padding: '2px', margin: '2px', color: 'white' }}
    // @ts-ignore
    color={isDefault ? '#2db7f5' : getColor(price)}
    className={className}>
      €{price}
    </Tag>
  );
}


export default PrintPriceRatio;
