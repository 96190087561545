import React from 'react';
import { PrintDateTimeFirst } from '../../../Components/Print/PrintDateTime';
import s from './styles.module.scss'
import { Badge, Tag } from 'antd';

interface ColumnDiscountExpire {
  date: number,
  isSubscribed: number,
  discount: number
};

const ColumnDiscountExpire:React.FC<ColumnDiscountExpire> = ({ date, isSubscribed, discount }) => {
  return (
    <>
    {date ? 
    <Badge.Ribbon text={discount +'%' || ''} placement="end" style={{position: 'relative', top: '-92px', right: '-92px', width: '40px', fontSize: '12px'}} color="rgb(32, 108, 32)">
    <div className={isSubscribed ? s.blue : s.green }>
    <PrintDateTimeFirst className="fs-12" date={date} />
    </div>
    </Badge.Ribbon>
    :
    <i>none</i>
    }
    {/* {discount ? <Tag color="green"><small>Discount: {discount}%</small></Tag> : ''} */}
    </>
  )
}

export default React.memo(ColumnDiscountExpire);
