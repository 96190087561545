//@ts-nocheck
import React from 'react';
import PopoverEditable from '../../PopoverEditable';
import ValidateMinCurrentProfit from '../../ChangeMinCurrentProfit/ValidateMinCurrentProfit';
import ChangeMinCurrentProfit from '../../ChangeMinCurrentProfit'
import MinCurrentProfit from '../../MinProfit';
import SteamSetDefaultProfit from '../../SetDefaultProfit';
import s from './styles.module.scss'
import ChangePriceRatio from '../../ChangePriceRatio';
import MinPriceRatio from '../../MinPriceRatio';


interface ColumnPriceRatio {
  value?: number,
  isLoading?: boolean,
  handleUpdateProfit?: void,
  mktProductId?: number,
  profit?: number,
  profitMin?: number,
  handleProfitOverwriteToggle?: void,
  mktId: number
  pltProductId: string,
  pltId: number
  isDefault: boolean,
  profitMinDef: number,
  profitDef: number
};

const ColumnPriceRatio: React.FC<ColumnPriceRatio> = ({
  isLoading,
  handleUpdateProfit,
  mktProductId,
  priceMax,
  priceMin,
  // profitMinDef,
  // profitDef,
  handleProfitOverwriteToggle,
  mktId,
  pltProductId,
  pltId,
  // isDefault
}) => {
  return (
    <div className="XBOXProductTable__profitColumn">
      <PopoverEditable
        titlePopover="Change price"
        className="mr"
        initialValue={[priceMin, priceMax]}
        withTitle
        title="Price (min / current)"
        isLoading={isLoading}
        // @ts-ignore
        handleSave={([newPriceMin, newPrice]: [number, number]) => {

          handleUpdateProfit([
            {
              pltId,
              mktId,
              // pltProductId,
              mktProductId,
              priceMax: newPrice,
              priceMin: newPriceMin,
            },
          ]);
        }}
        iconClassName={s.editIcon}
        changeBlock={(props: any) => <ChangePriceRatio priceMin={priceMin} priceMax={priceMax} {...props} />}
      >
        <MinPriceRatio priceMin={priceMin} priceMax={priceMax} className="cursor-pointer" />
      </PopoverEditable>
      <SteamSetDefaultProfit
        iconClassName={s.editIcon}
        isOverwrite={[!!priceMax, !!priceMin].includes(true)}
        mktId={mktId}
        mktProductId={mktProductId}
        handleProfitOverwriteToggle={handleProfitOverwriteToggle}
        value="price"
      />
    </div>
  );
}



export default ColumnPriceRatio;
