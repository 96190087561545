import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import useBoolean from '../../Utils/useBoolean';
import { Drawer, Form, Button, Input, message, Radio, Row, Col, Switch, Card, Checkbox, Tag, Typography } from 'antd';
import { PlusCircleTwoTone } from '@ant-design/icons';
// import { addMapping } from '../../Reducers/resell';
import s from './styles.module.scss'
import ProductCreateDigiseller from './CreateForms/Digiseller';
import SteamLookup from './CreateForms/SteamLookup';
import RadioRegions from '../RadioRegions';
import ProfitInput from '../Inputs/ProfitInput';
import PrintPrice from '../Print/PrintPrice';
import useForm from 'antd/lib/form/hooks/useForm';
import { createProductMapping } from '../../Reducers/dashboard';
import ProductCreateGamivo from './CreateForms/Gamivo';
import ProductCreateG2A from './CreateForms/G2A';
import LoadingOutlined from '@ant-design/icons/lib/icons/LoadingOutlined';
import ProductCreatePlatiMarket from './CreateForms/PlatiMarket';
import ProductCreateSearch from './CreateForms/Kinguin';

const { Title } = Typography

interface CreateProductForm {
  record?: any,
  afterUpdate?: any,
  list: any,
}

interface RegToCode {
  EUROPE: string,
  NORTH_AMERICA: string,
  GLOBAL: string,
  RUSSIA: string,
  CIS: string,
}

function PrintPriceData({ price, discount }) {
  if (!price) {
    return <>-</>;
  }

  return (
    <>
      <small>
        <b>EUR</b>
      </small>
      &nbsp;
      <PrintPrice price={price} />
      {discount && (
        <>
          &nbsp;(-&nbsp;{discount}
          %)
        </>
      )}
    </>
  );
}

const CreateProduct: React.FC<CreateProductForm> = ({
                      record,
                      afterUpdate,
                      list,
                      
                   }) => {

                    const regionsToCode: RegToCode = {
                      EUROPE: 'eu',
                      NORTH_AMERICA: 'na',
                      GLOBAL: 'glo',
                      RUSSIA: 'ru',
                      CIS: 'cis',
                    };
  

  const dispatch = useDispatch();

  const [form] = useForm()
  const { getFieldValue } = form;

  const [isProcessing, setIsProcessing] = useState(false)
  const [market, setMarket] = useState(3);
  const [steamSelected, setSteamSelected] = useState(null);
  const [productName, setProductName] = useState('')
  const [mktProductIdTest, setMktProductId] = useState(null);
  const [productSlug, setProductSlug] = useState('');
  const [parentCategoryId, setParentCategoryId] = useState(null);
  const [categoryId, setCategoryId] = useState(null);
  const [isSub, setIsSub] = useState<boolean>();
  const [region, setRegion] = useState('');
  const [enabled, setEnabled] = useState(true);
  const [active, setActive] = useState(false);
  const [overwriteProfit, setOverwriteProfit] = useState(false);
  const [profitValue, setProfitValue] = useState(null);
  const [minProfitValue, setMinProfitValue] = useState(null);

  // console.log('mktProductId', mktProductIdTest)
  // console.log('productSlug', productSlug)
  

  const options = [
    { label: 'GMVO', value: 3 },
    { label: 'Kinguin', value: 7 },
    { label: 'G2A', value: 4, disabled: true },
    { label: 'GGSEL', value: 5, disabled: true },
    { label: 'Plati', value: 6, disabled: true },
  ];

  const onChange = (e: any) => {
    e.preventDefault()
    setMarket(e.target.value)
  }

  const { value: isOpen, setFalse: close, setTrue: open } = useBoolean(false);

  const handleSetActive = () => {
    setActive((prev) => !prev)
  }

  const handleSetEnabled = () => {
    setEnabled((prev) => !prev)
  }

  const handleChangeProfit = useCallback(
    (value) => {
      setProfitValue(value)
    },
    [profitValue],
  );

  const handleChangeProfitMin = useCallback(
    (value) => {
      setMinProfitValue(value)
    },
    [minProfitValue],
  );

  const onSubmit = useCallback(
    (e) => {
      // setIsProcessing(true)
      console.log('mktProductId', mktProductIdTest)
      // e.preventDefault();
      // if (selectRegionError) return;
        let formData
        
        if(market === 5 || market === 6) {
          formData = {
            mktId: market,
            mktProductId: mktProductIdTest.toString(), // handle After
            // pltId: in reducer service as 2
            pltProductId: steamSelected.subId,
            pltRegion: getFieldValue('region'),
            isActive: active,
            enabled: enabled,
            profitMin: minProfitValue,
            profitMax: profitValue,
            setDefault: [
              "profit"
            ],
              ext: {
              "@class": "es.ibs.tech.dashboard.digiseller.DigiProductMappingExt",
              categoryId: categoryId,
              name: steamSelected.app_name,
              parentCategoryId: parentCategoryId,
              isSub: isSub
              }
              }
            } else {
              formData = {
                mktId: market,
                mktProductId: mktProductIdTest.toString(), // handle After
                // pltId: in reducer service as 2
                pltProductId: steamSelected.subId,
                pltRegion: getFieldValue('region'),
                isActive: active,
                enabled: enabled,
                profitMin: minProfitValue,
                profitMax: profitValue,
                setDefault: [
                  "profit"
                ],
                ext: {
                  "@class": "es.ibs.tech.dashboard.gamivo.GamivoProductMappingExt",
                  slug: productSlug
                  }
                  }
            } 
          
          if (overwriteProfit) {
            if (profitValue === null) {
              message.error('Profit and / or ProfitMin must be filled in')
            }
            if (minProfitValue === null) {
              message.error('Profit and / or ProfitMin must be filled in')
              }
            

            if ((profitValue === null && minProfitValue === null) && (profitValue || minProfitValue)) {
              if (profitValue) {
                formData.profitMax = +profitValue / 100;
              } else {
                formData.profitMin = +minProfitValue / 100;
              }
            } else {
              if (+minProfitValue > +profitValue) {
                  // setProfitValue(profitValue)
                  message.error('Profit must be greater than ProfitMin')
                  // setMinProfitValue(minProfitValue)
                  // message.error('ProfitMin must be less than Profit')
                  return;
                  }

              formData.profitMin = +minProfitValue / 100;
              formData.profitMax = +profitValue / 100;
              
            }
          }

          
          // console.log('formData', formData)
          dispatch(createProductMapping(formData))
          //@ts-ignore
          .then((res) => {
            !res.error && message.success('Product has been created!');
            form.resetFields()
            afterUpdate()
            close()
            setIsProcessing(false)
          })
          .catch((e) => {
            message.error(`Failed to create product: ${e}`)
            setIsProcessing(false)
          })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [steamSelected, overwriteProfit, minProfitValue, profitValue, mktProductIdTest],
  );

  const regionSelected = getFieldValue('region')
  console.log('region', region)

  return (
    <>
      <Drawer visible={isOpen} title="Create New Product" width={700} closable placement="right" onClose={close}>
        <Form form={form} layout="vertical" className={s.addMaping} onFinish={onSubmit}>
        <Row gutter={10}>
          <Col span={16}>
            <Radio.Group
            style={{marginBottom: '12px'}}
            options={options}
            onChange={onChange}
            value={market}
            optionType="button"
            defaultValue={market}
            />
          </Col>
        </Row>
        <Row gutter={10}>
          <Col span={16}>
            {/* <Title>Games Search</Title> */}
            <Form.Item name="market_lookup">
              {market === 6 ?
              <ProductCreatePlatiMarket setCategoryId={setCategoryId} setParentCategoryId={setParentCategoryId} setMktProductId={setMktProductId} setIsSub={setIsSub} />
              : market === 5 ? 
              <ProductCreateDigiseller setCategoryId={setCategoryId} setParentCategoryId={setParentCategoryId} setMktProductId={setMktProductId} />
             : market === 3 ?
              <ProductCreateGamivo setMktProductId={setMktProductId} setProductSlug={setProductSlug}/>
              : market === 4 ?
               <ProductCreateG2A setMktProductId={setMktProductId}/>
               :
               <ProductCreateSearch setMktProductId={setMktProductId}/>
              }
            </Form.Item>
            </Col>
          </Row>

          {/* <Row gutter={10}>
          <Col span={16}>
            <Form.Item name="test">
              <GamesLookup />
            </Form.Item>
            </Col>
          </Row> */}

          <Row gutter={10}>
          <Col span={16}>
            <Form.Item name="region">
              <RadioRegions setRegion={setRegion} />
            </Form.Item>
            </Col>
          </Row>

          <Row gutter={10}>
          <Col span={16}>
            <Form.Item label="Steam Search" name="steam_lookup">
              
              <SteamLookup getFieldValue={getFieldValue} onSelect={setSteamSelected} />
              {/* {existingProductAlert && <span style={{color: 'red'}}>This product already exists!</span>} */}
            </Form.Item>
            </Col>
          </Row>

          {market !== 3 && market !== 4 && market !== 7 &&
          <Row gutter={10}>
          <Col span={16}>
            <Form.Item name="ggsel_name">
            {steamSelected && steamSelected.app_name?.length > 55 &&  <span style={{color: 'red'}}>Name should be no more than 55 symbols long</span> }
              <Input value={steamSelected?.app_name || null} onChange={(e) => setSteamSelected({...steamSelected, app_name: e.target.value})}/>
            </Form.Item>
            </Col>
          </Row>
           }

          <Row gutter={5}>
          <Col span={5}>
            <Form.Item name="active" label={active ? 'Active' : 'Non-Active'}>
              <Switch onChange={handleSetActive}/>
            </Form.Item>
            <Form.Item name="enabled" label={enabled ? 'Enabled' : 'Not Enabled'}>
              <Switch onChange={handleSetEnabled} defaultChecked/>
            </Form.Item>
            </Col>
          </Row>


          <Row gutter={10} className={s.mbSmall}>
          <Col span={16}>
          <Card size="small" style={{ minHeight: '180px', marginBottom: '12px' }} >
          
            <Form.Item
            name="overwrite_profit"
            className={s.G2AProductCreateForm__formItem}
              label={
                <>
                  <Checkbox
                    className={s.fr}
                    onChange={(e) => {
                      if (!e.target.checked) {
                        setMinProfitValue(null)
                      }
                      setOverwriteProfit(e.target.checked);
                    }}
                    checked={overwriteProfit}
                    disabled={!steamSelected}
                  >
                    Overwrite
                  </Checkbox>
                  Profit Min
                </>
              }
              >
          <Row gutter={16} align="middle">
          <Col span={24}>
              <ProfitInput overwriteProfit={overwriteProfit} handleChangeProfit={handleChangeProfitMin} />
              </Col>
              </Row>
        </Form.Item>
        <Form.Item  name="profit" className={s.G2AProductCreateForm__formItem} label="Profit">
            <Row gutter={16} align="middle">
                <Col span={24}>
              <ProfitInput overwriteProfit={overwriteProfit} handleChangeProfit={handleChangeProfit} />
              </Col>
              </Row>
        </Form.Item>

            {steamSelected && (
              <Row className="mb-small">
                {steamSelected.gamepass && <Tag color="green">Game Pass</Tag>}
                {steamSelected.ea && (
                  <Tag color="blue" className="ml">
                    EA
                  </Tag>
                )}
                {steamSelected.gld && (
                  <Tag color="yellow" className="ml">
                    Gold
                  </Tag>
                )}
              </Row>
            )}
            <Row className="mb-small">
              Price Initial:&nbsp;
              <PrintPriceData
                price={steamSelected && steamSelected.price_initial}
                discount={steamSelected && steamSelected.discount}
              />
            </Row>
            <Row>
              Price Discount:&nbsp;
              <PrintPriceData price={steamSelected && steamSelected.price_discount} discount={''} />
            </Row>
          </Card>
            </Col>
          </Row>
          


            {/* <Input disabled value={market}  addonBefore="Market" />
            <Input disabled value={mktId} />
            <Input disabled value={platform} addonBefore="Platform"  />
            <Input disabled value={pltProductId} addonBefore="Product ID"  />
            <Input disabled value={pltRegion} addonBefore="Region"  /> */}
          {/* <Form.Item
            label="New market product ID"
            name="mktProductId"  
            rules={[
              {
                required: true,
                message: 'please enter new market product ID',
              },
          ]}>
            <Input />
          </Form.Item> */}
          <Form.Item>
          {isProcessing ? 
            <Button
                  type="primary"
                  htmlType="submit"
                  disabled
              >
                  <LoadingOutlined />
              </Button>
             : 
              <Button
                  className={s.button}
                  type="primary"
                  htmlType="submit"
              >
                  Submit
              </Button>
            }
          </Form.Item>
        </Form>
      </Drawer>
      <Button onClick={open} className={s.button}>
          <PlusCircleTwoTone className={s.createProductIcon} />
          Create New Product
      </Button>
    </>
  );
};

export default React.memo(CreateProduct);
