//@ts-nocheck
import React from 'react';
import { utcToDefaultFormatWithTime, utcToFormatWithTimeFirst, utcToFormatWithTimeLast } from '../../../Utils/date';
import moment from 'moment';

interface PrintDateTime {
  date: string | number,
  className: string,
};

interface PrintDateTimeFirst extends PrintDateTime {}
interface PrintDateTimeLast extends PrintDateTime {}

const PrintDateTime:React.FC<PrintDateTime> = ({ date, className }) => {
  return <span className={className}>{utcToDefaultFormatWithTime(+date)}</span>;
}

export const PrintDateTimeToHours: React.FC<PrintDateTimeProps> = ({ date, className }) => {
  const formattedDate = utcToDefaultFormatWithTime(+date);
  const isWithin24Hours = moment(+date).isAfter(moment().subtract(24, 'hours'));

  if (isWithin24Hours) {
    const relativeTime = moment(+date).fromNow();
    return <span className={className}>{relativeTime}</span>;
  }

  return <span className={className}>{formattedDate}</span>;
};

export const PrintDateTimeFirst = ({ date, className }:PrintDateTimeFirst) => {
  return <span className={className}>{utcToFormatWithTimeFirst(+date)}</span>;
}

export const PrintDateTimeLast = ({ date, className }:PrintDateTimeLast) => {
  return <span className={className}>{utcToFormatWithTimeLast(+date)}</span>;
}


export default PrintDateTime;
