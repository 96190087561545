import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import useBoolean from '../../../Utils/useBoolean';
import {Drawer, Form, Button, message, InputNumber} from 'antd';
import { EditFilled } from '@ant-design/icons';
import s from './styles.module.scss'
import { groupUpdateProducts, groupUpdateProfit } from '../../../Reducers/dashboard';
import { Filters } from '../../../types/rootState';
import splitArray from '../../../Utils/splitArray';

interface GroupChange {
  afterUpdate: any,
  selected: string[],
  finalCountRecords: number,
  filters: Filters,
  query: string
}

type ProfitData = {
  profitMin: number,
  profitMax: number
}

const EdirProfit: React.FC<GroupChange> = ({
                     afterUpdate,
                     selected,
                     finalCountRecords,
                     filters,
                     query
                   }) => {

  const dispatch = useDispatch();
  // console.log('FILTERS', filters)
  // console.log('finalCountRecords', finalCountRecords)

  const { value: isOpen, setFalse: close, setTrue: open } = useBoolean(false);

  const handleSuccess = useCallback((data: ProfitData) => {
    if (selected.length) {
    
    const newArr = selected.map((el) => el.split(','))
    const preparedArr = splitArray(newArr)

    
    for (let key in data) {
      //@ts-ignore
      data[key] = (+data[key] / 100)
    }

    const handleData = preparedArr.map((item) => {
      return {...item, ...data}
    })
    //@ts-ignore
    dispatch(groupUpdateProducts(handleData)).then(() => {
      message.success('Profits have been changed!');
      afterUpdate();
    }).catch((error: string) => {
      // console.log(error);
      message.error(`Can\'t edit profits: ${error} `);
    });
    close();
  }

    if (!selected.length) {
      const paramsData = {
        d: [{profitMin: (data.profitMin) / 100, profitMax: (data.profitMax) / 100}],
        filters,
        query,
      }
      //@ts-ignore
      dispatch(groupUpdateProfit(paramsData)).then(() => {
        message.success('Actions have been changed!');
        afterUpdate();
      }).catch((error: string) => {
        message.error(`Can\'t edit actions: ${error}`);
      });
      close();

    }
  }, [dispatch, afterUpdate, close, selected]);
  
  return (
    <>
      <Drawer visible={isOpen} title="Edit Profit" width={300} closable placement="right" onClose={close}>
        <Form layout="vertical" onFinish={handleSuccess}>
          <Form.Item
            label="New minimum profit"
            name="profitMin"  
            rules={[
              {
                required: true,
                message: 'please enter new minimum profit',
              },
          ]}>
            <InputNumber />
          </Form.Item>
          <Form.Item
            label="New maximum profit"
            name="profitMax"  
            rules={[
              {
                required: true,
                message: 'please enter new maximum profit',
              },
          ]}>
            <InputNumber />
          </Form.Item>
          <Form.Item>
            <Button
                  type="primary"
                  htmlType="submit"
              >
                  Submit
              </Button>
          </Form.Item>
        </Form>
      </Drawer>
      <Button
        type="primary"
        disabled={!finalCountRecords}
        icon={<EditFilled />}
        onClick={open}
        className={s.changeRatios}
      >
        Edit profit
      </Button>
    </>
  );
};

export default React.memo(EdirProfit);
