import React from 'react';
import { Tag } from 'antd';


interface PrintPercent {
  percent?: number,
  className?: string,
  isDefault?: boolean
};

function getColor (percent: number): string | null {
  if (+percent < 15) {
    return '#e25757';
  }

  if (+percent > 40) {
    return 'rgb(53, 179, 53)';
  }

  return '#28323f';
}

const PrintPercent: React.FC<PrintPercent> = ({ percent, className, isDefault }) => {
  if (!percent || percent === null) {
    return <span className={className}>default</span>;
  }

  
  return (
    <Tag 
    style={{ margin: '2px', color: 'white' }}
    // @ts-ignore
    color={isDefault ? '#2db7f5' : getColor(percent)}
    className={className}>
      {percent}%
    </Tag>
  );
}


export default PrintPercent;
