import React from 'react';
import { Radio } from 'antd';

interface ActionButtonsProps {
  handleClick: (value: string) => void;
  hasStock: boolean;
  isActive: boolean;
}


const ActionButtons: React.FC<ActionButtonsProps> = ({ handleClick, hasStock, isActive }) => {

  return (
    <div>
    
    <Radio.Group 
        // onClick={(e) => handleClick(e.target.value)}
        // value={value.toString()}
        optionType="button"
        // buttonStyle={!mktActive ? "" : "solid"}
        buttonStyle="solid"
        size="small"
        >
          <Radio.Button style={!hasStock ? (!isActive ? {background: 'grey'} : {background: '', color: 'black'}) : {background: '', color: 'black'}} onClick={(e: any) => handleClick(e.target.value)} value='{"isActive": false}' >Off</Radio.Button>
          <Radio.Button style={hasStock ? (isActive ? {background: 'linear-gradient(90deg, #06BFFF 0%, #2D73FF 100%)', color: ''} : {background: '', color: 'black'}) : {background: '', color: 'black'}} onClick={(e: any) => handleClick(e.target.value)} value='{"isActive": true, "hasStock": true}' >Stock</Radio.Button>
          <Radio.Button style={!hasStock ? (isActive ? {background: 'linear-gradient(90deg, #06BFFF 0%, #2D73FF 100%)', color: ''} : {background: '', color: 'black'}) : {background: '', color: 'black'}} onClick={(e: any) => handleClick(e.target.value)} value='{"isActive": true, "hasStock": false}' >On</Radio.Button>
    </Radio.Group>
    </div>
  );
}

export default ActionButtons;
