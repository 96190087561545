import { Checkbox, Col, Input, InputNumber, Modal, Row, Slider } from 'antd';
import React from 'react';
import OrderKeysButton from '../OrderKeysButton';
import styles from './styles.module.scss';
import cx from '../../../Utils/cx';

interface OrderKeys extends OrderKeysButton {
  visible: boolean,
  close: ((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void) | undefined,
  onOk: ((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void) | undefined,
}

const OrderKeysModal: React.FC<OrderKeys> = ({ visible, close, onOk, pltId, pltProductId, region, pltName }) => {
  return (
    <Modal
    open={visible}
    onCancel={close}
    onOk={onOk}
    title={`Order Keys: ${pltName || ''} (${region})`}
    destroyOnClose
    maskClosable
    closable={false}
    okButtonProps={{
      disabled: true
    }}
    >
      <Row className={styles.mbSmall}>
        <Col span={4} className={styles.flexLeft} style={{ minHeight: '32px' }}>
          <b>Quantity:</b>
        </Col>
        <Col span={15}>
          <Slider
            min={1}
            // max={buyFromStock ? stockAmount : 500}
            max={500}
            // onChange={setNumber}
            // value={typeof number === 'number' ? number : 0}
          />
        </Col>
        <Col span={5}>
          <InputNumber
            min={1}
            // max={buyFromStock ? stockAmount : 500}
            max={500}
            className={cx(styles.marginLeft, styles.floatRight)}
            // value={number}
            // onChange={(v) => {
            //   let n = Math.round(v);
            //   if (n < 1) n = 1;
            //   // const max = buyFromStock ? stockAmount : 500;
            //   const max = 500;
            //   if (n > max) n = max;
            //   setNumber(n);
            // }}
          />
        </Col>
      </Row>

      <Row className={styles.mbSmall}>
        <Col span={19} className={styles.flexLeft} style={{ minHeight: '32px' }}>
          <b>Sell price, €:</b>
        </Col>
        <Col span={5} style={{ paddingLeft: 8 }}>
          <InputNumber
            // readOnly={!skipOrderPriceValidation}
            className={styles.floatRight}
            min={0.01}
            // value={maxPrice}
            precision={2}
            // onChange={setMaxPrice}
          />
        </Col>
      </Row>

      <Row className={cx(styles.mbSmall, styles.flexLeft)}>
        <Col span={6}>
          <b>Order ID:</b>
        </Col>
        <Col span={18}>
          <Input
            // value={orderID}
            // onChange={changeOrderID}
            type="text"
            className={styles.w100p}
          />
        </Col>
      </Row>

      <Row style={{ marginTop: 15 }} >
        <Col span={19} className={styles.flexLeft} >
          <b>Immediate</b>
        </Col>
        <Col span={5} style={{ paddingLeft: 8 }}>
          <Checkbox
            // onChange={changeImmediate}
            className={styles.floatRight}
            // checked={immediate}
          />
        </Col>
      </Row>

      <Row style={{ marginTop: 15 }} >
        <Col span={19} className={styles.flexLeft} >
          <b>Quantity on stock:</b>
        </Col>
        <Col span={5} style={{ paddingLeft: 8 }} >
          <span style={{ float:"right" }}>-</span>
        </Col>
      </Row>
      <Row style={{ marginTop: 15 }} >
        <Col span={19} className={styles.flexLeft} >
            <b>Buy from stock</b>
        </Col>
        <Col span={5} style={{ paddingLeft: 8 }}>
            <Checkbox
              // disabled={disableBuyFromStock}
              // defaultChecked={disableBuyFromStock}
              // onChange={changeBuyFromStock}
              className={styles.floatRight}
            />
        </Col>
      </Row>
      
    </Modal>
  )
}

export default OrderKeysModal
