//@ts-nocheck
import React from 'react';
import PopoverEditable from '../../../Components/PopoverEditable';
import ValidateMinCurrentProfit from '../../../Components/ChangeMinCurrentProfit/ValidateMinCurrentProfit';
import ChangeMinCurrentProfit from '../../../Components/ChangeMinCurrentProfit'
import MinCurrentProfit from '../../../Components/MinProfit';
import SteamSetDefaultProfit from '../../../Components/SetDefaultProfit';
import s from './styles.module.scss'


interface ColumnProfit {
  value?: number,
  isLoading?: boolean,
  handleUpdateProfit?: void,
  mktProductId?: number,
  profit?: number,
  profitMin?: number,
  handleProfitOverwriteToggle?: void,
  mktId: number
  pltProductId: string,
  pltId: number
  isDefault: boolean,
  profitMinDef: number,
  profitDef: number
};

const ColumnProfit: React.FC<ColumnProfit> = ({
  isLoading,
  handleUpdateProfit,
  mktProductId,
  profit,
  profitMin,
  profitMinDef,
  profitDef,
  handleProfitOverwriteToggle,
  mktId,
  pltProductId,
  pltId,
  isDefault
}) => {
  return (
    <div className={s.profitColumn}>
      <PopoverEditable
        className="mr"
        initialValue={[profitMin, profit]}
        withTitle
        title="Profit (min / current)"
        isLoading={isLoading}
        // @ts-ignore
        handleSave={([newProfitMin, newProfit]: [number, number]) => {
          if (!ValidateMinCurrentProfit(profitMin, newProfitMin, profit, newProfit)) {
            return;
          }

          handleUpdateProfit([
            {
              pltId,
              mktId,
              // pltProductId,
              mktProductId,
              profitMax: newProfit && newProfit / 100,
              profitMin: newProfitMin && newProfitMin / 100,
            },
          ]);
        }}
        iconClassName={s.editIcon}
        changeBlock={(props: any) => <ChangeMinCurrentProfit profitMin={profitMin} profit={profit} {...props} />}
      >
        <MinCurrentProfit isDefault={isDefault} profitMin={isDefault ? Math.round(profitMinDef * 100) : Math.round(profitMin * 100)} currentProfit={isDefault ? Math.round(profitDef * 100) : Math.round(profit * 100)} className="cursor-pointer" />
      </PopoverEditable>
      <SteamSetDefaultProfit
        iconClassName={s.editIcon}
        isOverwrite={[!!profit, !!profitMin].includes(true)}
        mktId={mktId}
        mktProductId={mktProductId}
        handleProfitOverwriteToggle={handleProfitOverwriteToggle}
        value="profit"
      />
    </div>
  );
}



export default ColumnProfit;
