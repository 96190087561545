import React, { useCallback } from 'react';
import { Popconfirm, Popover } from 'antd';
import ThunderboltOutlined from '@ant-design/icons/lib/icons/ThunderboltOutlined';
import QuestionCircleOutlined from '@ant-design/icons/lib/icons/QuestionCircleOutlined';


interface SteamSetDefaultProfitProps {
  isOverwrite: boolean,
  mktProductId: number,
  mktId: number,
  handleProfitOverwriteToggle: any,
  value: string
};

const SteamSetDefaultProfit: React.FC<SteamSetDefaultProfitProps> = ({ isOverwrite, mktProductId, mktId, handleProfitOverwriteToggle, value }) => {
  const handleClick = useCallback(() => {
    handleProfitOverwriteToggle([
      {
        mktId,
        mktProductId,
        setDefault: [value],
      },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mktId, mktProductId, isOverwrite]);

  if (!isOverwrite) {
    return <></>;
  }

  return (
    <Popconfirm
      title={`Are you sure you want to set default ${value} for this game?`}
      onConfirm={handleClick}
      okText="Yes"
      cancelText="No"
      icon={<QuestionCircleOutlined />}
    >
      <Popover
        content={
          <>
            Set default {value}
          </>
        }
      >
        <ThunderboltOutlined />
      </Popover>
    </Popconfirm>
  );
}

export default SteamSetDefaultProfit;
