import React, { useCallback, useEffect, useState } from 'react';
import s from './styles.module.scss'


interface ColumnName {
  pltId: number,
  id: number,
  region: string,
  url: string,
  afterUpdate: any
};

const ColumnGroup: React.FC<ColumnName> = ({ pltId, id, region, url, afterUpdate }) => {


  return (
    <>
      <div className={s.groupId}><a style={{ fontSize: '10px'}} className={s.linkToMarket} target="_blank" rel="noopener noreferrer" href={url} >{id}</a></div>
    </>
  );
}

export default ColumnGroup;
