// new api
export const core = `/api/v3`
// new api without steam
export const common = `/api/v3`
// for steam lookup
export const steamprices = '/steam-prices/api/v1';
// for ggsel lookup
export const ggsel = '/digiseller/api/v1'
// for gmvo lookup
export const gamivo = `/gamivo/api/v1`;
// for kinguin lookup
export const kinguin = `/kinguin/steam/products`;
// for g2a lookup
export const arena = '/arena/api/v2';
