import React from 'react';
import AuthLayout from '../../Components/AuthLayout';
import SignInForm from '../../Components/SignInForm';
import s from './styles.module.scss';



function SignIn() {

  // console.log(window.navigator.userAgent.indexOf("Windows") != -1);

  return (
    <AuthLayout className={s.authLayout}>
      <div className={s.auth}>
        <SignInForm />
      </div>
    </AuthLayout>
  );
}

export default SignIn;
