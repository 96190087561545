import React, { useCallback } from 'react';
import { Col, Row, Tooltip, message } from 'antd';
import { copyToClipboard } from '../../../Utils/textTools';
import CopyOutlined from '@ant-design/icons/lib/icons/CopyOutlined';
import DeleteOutlined from '@ant-design/icons/lib/icons/DeleteOutlined';
import s from './styles.module.scss'
import ChangeStatusLock from '../../../Components/ChangeStatusLock';
import { useDispatch } from 'react-redux';
import { lockUnlock, mappingDelete } from '../../../Reducers/dashboard';
import DeleteMapping from '../../../Components/DeleteMapping';

interface ColumnMarkets {
  mktProductId: number,
  marketLink?: string,
  market: any,
  enabled: boolean,
  mktId: number,
  afterUpdate: any
};

const ColumnMarkets: React.FC<ColumnMarkets> = ({market, mktId, marketLink, mktProductId, enabled, afterUpdate }) => {

  const dispatch = useDispatch()

  const handleLockStatusToggle = useCallback((mktId, mktProductId, value) => {
    if (!mktProductId) {
      message.error('Market product ID is absent.');
    } else {
      //@ts-ignore
      dispatch(lockUnlock({ mktId, mktProductId, value })).then(() => {
        if(value) {
          message.success('Product unlocked!');
          afterUpdate()
        } else {
          message.success('Product locked!');
          afterUpdate()
        }
        // afterUpdate()
      }).catch((error: string) => {
        console.log(error);
        message.error('Can\'t change product status!');
      });
    }
  }, [dispatch, afterUpdate]);

  const handleDeleteMapping = useCallback((mktId, mktProductId) => {
    if (!mktProductId) {
      message.error('Market product ID is absent.');
    } else {
      //@ts-ignore
      dispatch(mappingDelete({ mktId, mktProductId })).then(() => {
          message.success('Mapping deleted!');
          afterUpdate()
      }).catch((error: string) => {
        console.log(error);
        message.error('Can\'t delete mapping!');
      });
    }
  }, [dispatch, afterUpdate]);


  const handleClick = useCallback(() => {
    copyToClipboard(mktProductId);
    message.success('Steam ID has been copied!');
    // eslint-disable-next-line
  }, [mktProductId]);
  return (
    <>
            {/* <div className={s.borderedRow}> */}
            <Row style={{padding: '54px 0'}} >
              <Col span={24}>
              <Tooltip placement="top" title={market}>
              <img src={`${market}.png`} alt={market} style={market === 'G2A' ? {width: '25px', marginRight: '8px'} : {width: '18px', marginRight: '8px', borderRadius: '50%'}} />
              </Tooltip>
              <a target="_blank" rel="noopener noreferrer" href={marketLink} className={s.linkToMarket}>
                {mktProductId}
              </a>
              <CopyOutlined style={{fontSize: '10px', marginLeft: '2px'}} onClick={handleClick} />
              <ChangeStatusLock value={enabled} mktId={mktId} id={mktProductId} handleLockStatusToggle={handleLockStatusToggle} />
              <DeleteMapping mktId={mktId} id={mktProductId} handleDeleteMapping={handleDeleteMapping} />
              </Col>
              </Row>
            {/* </div> */}
    
    </>
  );
}


export default ColumnMarkets;
