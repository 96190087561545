import { createAction, createReducer } from '@reduxjs/toolkit';
import frc from 'front-end-common';
import DashboardService from '../Services/DashboardService';

const { getDefaultHandler, getDefaultState } = frc.utils.reducerTools;

//Status for sync table
export const statusFetch = createAction('status', () => ({
  payload: DashboardService.fetchStatus(),
}))


export const listFetch = createAction('list', (params) => ({
  payload: DashboardService.fetchOrderedList(params),
}))

export const MarketsFetch = createAction('markets', () => ({
  payload: DashboardService.fetchMarketsList(),
}))

export const fetchRegions = createAction('regionsSteam', () => ({
  payload: DashboardService.fetchRegionsSteam(),
}));

export const commentsFetch = createAction('comments', (params) => ({
  payload: DashboardService.fetchComments(params),
}))

export const addNewComment = createAction('commentsAdd', (params, comment) => ({
  payload: DashboardService.addComment(params, comment),
}))

// Create Mapping states
export const createProductMapping = createAction('productCreate', (data) => ({
  payload: DashboardService.createProduct(data)
}));

export const lookupGames = createAction('lookGames', (q, sub) => ({
  payload: DashboardService.gameLookup(q, sub),
}));

export const lookupSteam = createAction('lookSteam', (q, region) => ({
  payload: DashboardService.steamLookup(q, region),
}));

export const lookupDigisel = createAction('lookDigi', (q) => ({
  payload: DashboardService.digiLookup(q),
}));

export const lookupPlatiMarket = createAction('lookPlatiMkt', (q) => ({
  payload: DashboardService.platiMarketLookup(q),
}));

export const lookupG2A = createAction('lookG2A', (q) => ({
  payload: DashboardService.g2aLookup(q),
}));

export const lookupGamivo = createAction('lookGmvo', (q) => ({
  payload: DashboardService.gamivoLookup(q),
}));

export const lookupKinguin = createAction('lookKinguin', (q) => ({
  payload: DashboardService.kinguinLookup(q),
}));

// Update products state
export const groupUpdateProducts = createAction('changeProducts', (data) => ({
  payload: DashboardService.groupUpdate(data),
}))

export const groupUpdateStatus = createAction('changeStatusGroup', (data) => ({
  payload: DashboardService.applyStatusToProducts(data),
}))

export const groupUpdateProfit = createAction('changeProfitGroup', (data) => ({
  payload: DashboardService.changeProfit(data),
}))

export const groupSetDefaultProfit = createAction('changeDefaultProfitGroup', (data) => ({
  payload: DashboardService.setDefaultProfit(data),
}))

export const changeAction = createAction('changeAction', (param) => ({
  payload: DashboardService.setAction(param),
}));

export const updateProfit = createAction('updateProfit', (param) => ({
  payload: DashboardService.steamUpdateProfit(param),
}));

export const profitOverwrite = createAction('profitOverwrite', (param) => ({
  payload: DashboardService.profitOverwriteToggle(param),
}));

export const lockUnlock = createAction('changeStatusLock', (data) => ({
  payload: DashboardService.setLockProduct(data),
}))


// Mapping updates
export const groupMappingCreate = createAction('addMapping', (data) => ({
  payload: DashboardService.postGroupMapping(data),
}))

export const groupMappingDelete = createAction('deleteMapping', (data) => ({
  payload: DashboardService.deleteGroupMapping(data),
}))

export const mappingDelete = createAction('deleteMappingSolo', (data) => ({
  payload: DashboardService.deleteMapping(data),
}))

export const updatePltProductId = createAction('platformProductIdUpdate', (data) => ({
  payload: DashboardService.updatePlatformProductId(data),
}))

// Dashboard settings
export const productListSettingsFetch = createAction('settingsFetch', () => ({
  payload: DashboardService.fetchSettings()
}))

export const productListSettingsFetchReset = createAction('settingsFetchReset')

export const productListSettingsSchema = createAction('settingsSchemaFetch', () => ({
  payload: DashboardService.fetchSettingsSchema()
}))

export const productListSettingsUISchema = createAction('settingsUiSchemaFetch', () => ({
  payload: DashboardService.fetchSettingsUISchema()
}))

export const productListSettingsUpdate = createAction('settingsUpdate', (data) => ({
  payload: DashboardService.updateSettings(data)
}))

export const productListSettingsUpdateReset = createAction('settingsUpdateReset') 

const initState = { 
  status: getDefaultState(),
  productsList: getDefaultState(),
  markets: getDefaultState(),
  regionsSteam: getDefaultState(),
  comments: getDefaultState(),
  commentsAdd: getDefaultState(),
  productCreate: getDefaultState(),
  lookGames: getDefaultState(),
  lookSteam: getDefaultState(),
  lookDigi: getDefaultState(),
  lookPlatiMkt: getDefaultState(),
  lookG2A: getDefaultState(),
  lookGmvo: getDefaultState(),
  lookKinguin: getDefaultState(),
  changeProducts: getDefaultState(),
  changeStatusGroup: getDefaultState(),
  changeProfitGroup: getDefaultState(),
  changeDefaultProfitGroup: getDefaultState(),
  changeAction: getDefaultState(),
  updateProfit: getDefaultState(),
  profitOverwrite: getDefaultState(),
  changeStatusLock: getDefaultState(),
  addMapping: getDefaultState(),
  deleteMapping: getDefaultState(),
  deleteMappingSolo: getDefaultState(),
  platformProductIdUpdate: getDefaultState(),
  settingsFetch: getDefaultState(),
  settingsFetchReset: getDefaultState(),
  settingsSchemaFetch: getDefaultState(),
  settingsUiSchemaFetch: getDefaultState(),
  settingsUpdate: getDefaultState(),
  settingsUpdateReset: getDefaultState(),
}

export const DashboardReducer = createReducer(initState, {
  ...getDefaultHandler(statusFetch, 'status'),
  ...getDefaultHandler(listFetch, 'productsList'),
  ...getDefaultHandler(MarketsFetch, 'markets'),
  ...getDefaultHandler(fetchRegions, 'regionsSteam'),
  ...getDefaultHandler(commentsFetch, 'comments'),
  ...getDefaultHandler(addNewComment, 'commentsAdd'),
  ...getDefaultHandler(createProductMapping, 'productCreate'),
  ...getDefaultHandler(lookupGames, 'lookGames'),
  ...getDefaultHandler(lookupSteam, 'lookSteam'),
  ...getDefaultHandler(lookupDigisel, 'lookDigi'),
  ...getDefaultHandler(lookupPlatiMarket, 'lookPlatiMkt'),
  ...getDefaultHandler(lookupG2A, 'lookG2A'),
  ...getDefaultHandler(lookupGamivo, 'lookGmvo'),
  ...getDefaultHandler(lookupKinguin, 'lookKinguin'),
  ...getDefaultHandler(groupUpdateProducts, 'changeProducts'),
  ...getDefaultHandler(groupUpdateStatus, 'changeStatusGroup'),
  ...getDefaultHandler(groupUpdateProfit, 'changeProfitGroup'),
  ...getDefaultHandler(groupSetDefaultProfit, 'changeDefaultProfitGroup'),
  ...getDefaultHandler(changeAction, 'changeAction'),
  ...getDefaultHandler(updateProfit, 'updateProfit'),
  ...getDefaultHandler(profitOverwrite, 'profitOverwrite'),
  ...getDefaultHandler(lockUnlock, 'changeStatusLock'),
  ...getDefaultHandler(groupMappingCreate, 'addMapping'),
  ...getDefaultHandler(groupMappingDelete, 'deleteMapping'),
  ...getDefaultHandler(mappingDelete, 'deleteMappingSolo'),
  ...getDefaultHandler(updatePltProductId, 'platformProductIdUpdate'),
  ...getDefaultHandler(productListSettingsFetch, 'settingsFetch'),
  ...getDefaultHandler(productListSettingsFetchReset, 'settingsFetchReset'),
  ...getDefaultHandler(productListSettingsSchema, 'settingsSchemaFetch'),
  ...getDefaultHandler(productListSettingsUISchema, 'settingsUiSchemaFetch'),
  ...getDefaultHandler(productListSettingsUpdate, 'settingsUpdate'),
  ...getDefaultHandler(productListSettingsUpdateReset, 'settingsUpdateReset'),
})


export default DashboardReducer
