import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
// import { createStore } from 'redux';
import createStore from './Boots/store';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import App from './Boots/App';
import TokenService from './Services/TokenService';
import { authSetIsAuthenticated } from './Reducers/auth';


const store = createStore({});

if (TokenService.hasToken()) {
  //@ts-ignore
  store.dispatch(authSetIsAuthenticated(true));
}

TokenService.subscribe(function(isAuthenticated) {
  store.dispatch(authSetIsAuthenticated(isAuthenticated));
});

const AppRouter = () => {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  );
};

//@ts-ignore
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(<AppRouter/>);
