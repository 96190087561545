import PropTypes from 'prop-types';
import React from 'react';
import { Popover, Tooltip } from 'antd';
import cx from '../../../Utils/cx';
import PrintAmount from '../../../Components/Print/PrintAmount';
import { ExclamationCircleTwoTone } from '@ant-design/icons';
import s from './styles.module.scss'

interface ColumnPrice {
  price: number,
  CURRENCY: string,
  priceForBuyers: number,
  alert: string
}

const printColumn: React.FC<ColumnPrice> = ({price, priceForBuyers, CURRENCY, alert}) => {
  return (
    <div className={cx(s.withTwoLine, !priceForBuyers ? s.grey : '')}>
      {alert && 
          <Tooltip placement="left" title={alert}>
            <ExclamationCircleTwoTone twoToneColor="#eb2f96" style={{float: 'right', marginTop: '5px'}} />
          </Tooltip> }
      <PrintAmount currency={CURRENCY} amount={price * 100} className={''} classNameAmount={''} colorize={false} withZero={false} />
      {/* <br /> */}
      <small>
        <PrintAmount currency={CURRENCY} amount={priceForBuyers * 100} className={''} classNameAmount={''} colorize={false} withZero={false} />
      </small>
      
    </div>
  );
}

const ColumnPrice: React.FC<ColumnPrice> = ({ price, priceForBuyers, CURRENCY, alert }) => {
  // if (!priceForBuyers)
  //   return (
  //     <Popover placement="right" content="Not synced with G2A">
  //       {printColumn(b, price, priceForBuyers, CURRENCY)}
  //     </Popover>
  //   );

  return printColumn({price, priceForBuyers, CURRENCY, alert});
}


export default ColumnPrice;
