import React from 'react'
import { Layout, Menu } from 'antd'
import Logout from '../Logout'
import { Link } from 'react-router-dom'
import s from './styles.module.scss'

const AntHeader = Layout.Header

function Header() {

  const navItems = [
    {
      label: (
        <Link to="/dashboard">
          Dashboard
        </Link>
      ),
      key: 'dashboard',
    },
    {
      label: (
        <Link to="/dashboard-settings">
          Settings
        </Link>
      ),
      key: 'dashboard-settings',
    },
    {
      label: (
        <Link to="/status">
          Sync Status
        </Link>
      ),
      key: 'status',
    },
    {
      label: (
        <Logout />
      ),
      key: 'logout',
    },
  ]

  return (
    <AntHeader className={s.header}>
      {/* <div className={styles.container}> */}
          <Menu
            defaultSelectedKeys={['dashboard']}
            mode="horizontal"
            className={s.navigation}
            items={navItems}
          />
      {/* </div> */}
    </AntHeader>
  )
}

export default React.memo(Header)
