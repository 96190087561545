/* eslint-disable react/prop-types */
//@ts-nocheck
import { Button, Form, Radio, Select, Tooltip, message } from 'antd';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { lookupGames, lookupSteam } from '../../../../Reducers/dashboard';
import clearG2AName from '../../../../Utils/clearG2AName';
import clearSpecialCharacters from '../../../../Utils/clearSpecialCharacters';
import cx from '../../../../Utils/cx';
import PrintPrice from '../../../Print/PrintPrice';
import s from './styles.module.scss';
import LoadingOutlined from '@ant-design/icons/lib/icons/LoadingOutlined';

const { Option } = Select;

const SteamLookupInput = ({ onSelect, getFieldValue }, props, ref) => {
  
  const dispatch = useDispatch();
  
  const [dynamicQuery, setDynamicQuery] = useState('');
  const [fetchedData, setFetchedData] = useState([]);
  const [subId, setSubId] = useState()

  console.log('dynamicQuery', dynamicQuery)
  console.log('region', getFieldValue('region'))
  // console.log('fetchedData', fetchedData)

  const { isLoading, regions } = useSelector(
    (state) => ({
      regions: state?.dashboard?.regionsSteam?.payload,
      isLoading: state?.dashboard?.regionsSteam?.isLoading,
    }),
    shallowEqual,
  );


  // const handleSelectRegion = (e) => {
  //   setRegion(e.target.value)
  // }

  const lookup = useSelector((state) => state.dashboard.lookSteam, shallowEqual);
  const lookupGame = useSelector((state) => state.dashboard.lookGames, shallowEqual);

  const [myRef, setMyRef] = useState(null);

  console.log('myRef', myRef)

  useEffect(() => {
    setMyRef(ref)
  }, [])
  
  

  // console.log('LOOKUP', lookup)

  // const onSearch = useCallback(
  //   (() => {
  //     const debouncedLookup = debounce((value) => {
  //       dispatch(lookupXbox(value, getFieldValue('region')));
  //       // dispatch(lookupXbox(value, region));
  //     }, 700);

  //     return (query) => {
  //       const cleared = clearSpecialCharacters(query);
  //       if (cleared.length >= 3) {
  //         debouncedLookup(cleared);
  //       }
  //     };
  //   })(),
  //   [],
  // );

  const onSearch = useCallback(
    ((e) => {
      const debouncedLookup = debounce((value) => {
        const cleared = clearSpecialCharacters(value);
        if (cleared.length >= 3) {
          dispatch(lookupSteam(cleared, getFieldValue('region')));
          setDynamicQuery(cleared); // Set the dynamic query here
        }
      }, 700);
  
      return (query) => {
        debouncedLookup(query);
      };
    })(),
    []
  );

  const setInputValue = useCallback(
    (value) => {
      myRef.rcSelect.setInputValue(clearG2AName(clearSpecialCharacters(value)) || '');
    },
    [myRef],
  );

  
  useEffect(() => {
    if (myRef && myRef.rcSelect && myRef.rcSelect.inputRef) {
      myRef.rcSelect.inputRef.onpaste = (event) => {
        const paste = (event.clipboardData || window.clipboardData).getData('text');

        event.preventDefault();
        const input = event.target;
        const start = input.selectionStart;
        const end = input.selectionEnd;
        const originString = input.value;
        const myContent = clearG2AName(paste);
        input.focus();
        setInputValue(originString.substring(0, start) + myContent + originString.substring(end, originString.length));
      };

      // eslint-disable-next-line no-return-assign
      return () => (myRef.rcSelect.inputRef.onpaste = null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myRef]);

  const handleFetchSteamId = useCallback(() => {
    dispatch(lookupGames(getFieldValue('region'), dynamicQuery))

    //@ts-ignore
      .then((res) => {
        console.log('res', [...res]);
        setFetchedData(res);
      })
      .catch((error) => {
        message.error(`Failed to fetch data: ${error}`)
      })
  }, [dynamicQuery, fetchedData]);

  return (
    <>
    <Select
      {...props}
      showSearch
      showArrow
      placeholder="Input search query"
      defaultActiveFirstOption={false}
      ref={myRef}
      // onChange={(e) => setSubId(e.target.value)}
      filterOption={false}
      onSearch={onSearch}
      onSelect={(optKey) => {
        if (lookup.payload) {
          onSelect(lookup.payload.find((opt) => opt.subId === optKey));
        }
      }}
      notFoundContent={<Button className={s.button} onClick={handleFetchSteamId}>New</Button>}
      // notFoundContent={
      //   !lookup.isLoading ? (
      //     <>
      //     <p>Empty list</p>
      //     <Button onClick={handleFetchXboxId}>New</Button>
      //     </>
      //   ) : (
      //     <p>
      //       <LoadingOutlined />
      //     </p>
      //   )
      // }
      className={cx(s.block, s.XBOXLookupInput)}
      loading={lookup.isLoading}
    >
    {lookup.isLoading ? (
    <LoadingOutlined />
  ) :  (
    lookup.payload && lookup.payload.map(({ subId, name, price_discount, is_preorder }) => {
      if (is_preorder) {
        return (
          <Option className={s.preOrder} key={subId}>
            <Tooltip placement="left" title="PRE-ORDER">
              {name} | {subId} | <PrintPrice price={price_discount} />
            </Tooltip>
          </Option>
        );
      }
  
      return (
        <Option key={subId}>
          {name} | {subId} | <PrintPrice price={price_discount} />
        </Option>
      );
    })
  )}

    {lookupGame.isLoading ? (
        <LoadingOutlined />
      ) : (
        lookupGame.payload && lookupGame.payload.map(({ subId, name, price_discount, is_preorder }) => {
          if (is_preorder) {
            return (
              <Option className={s.preOrder} key={subId}>
                <Tooltip placement="left" title="PRE-ORDER">
                  {name} | {subId} | <PrintPrice price={price_discount} />
                </Tooltip>
              </Option>
            );
          }
    
          return (
            <Option key={subId}>
              {name} | {subId} | <PrintPrice price={price_discount} />
            </Option>
          );
        })
      )}


    </Select>
    {/* </Form.Item> */}
    </>
  );
}

export default React.forwardRef(SteamLookupInput);
