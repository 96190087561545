import { Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
// import { MarketsFetch } from '../../../../reducers/dashboard';
import marketsToOptions from '../../../../Utils/marketToOptions';
import s from './styles.module.scss';
import { Filter } from '../../../../types/rootState';
import { LocalGroceryStoreTwoTone } from '@mui/icons-material';


type MarketsState = {
  dashboard: {
    markets: {
      payload: Record<string, number>
    }
  }
}

const SelectMarket: React.FC<Filter> = ({ onChange, value }) => {

  const dispatch = useDispatch();
  const [optionsData, setOptionsData] = useState([])

  const markets = useSelector((state: MarketsState) => state?.dashboard?.markets, shallowEqual);


  const marketsData = markets && markets.payload && markets.payload
  const marketsNames = markets && markets.payload && Object.keys(markets.payload)

  useEffect(() => {
    
    setOptionsData(marketsToOptions(marketsNames, marketsData))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [marketsData]);
  
  return (
    <>
      <div>
      <LocalGroceryStoreTwoTone style={{fontSize: '12px', marginRight: '4px', color: 'rgb(24, 144, 255)'}} />
        <b>Markets</b>
      </div>
      <div className={s.regionSelect}>
       <Select
          value={value}
          options={optionsData}
          mode="multiple"
          style={{ width: '215px' }}
          placeholder="Select"
          onChange={onChange}
        />
      </div>
    </>
  );
}

export default SelectMarket;
