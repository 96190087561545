import React from 'react';

interface PrintPriceProps {
  price?: any
}

const PrintPrice:React.FC<PrintPriceProps> = ({ price }) => {

  if (isNaN(price) || !price) return <>-</>;

  return (
    <>
      {new Intl.NumberFormat('en', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(Number(price))}
    </>
  );
}

export default React.memo(PrintPrice);
