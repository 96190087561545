//@ts-nocheck
import React, { useEffect, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { statusFetch } from '../../Reducers/dashboard';
import SyncTable from '../../Components/SyncTable';
import s from './styles.module.scss'

interface Status {
  error: null | string;
  isLoading: boolean;
  payload: {[key: string]: Array<{ accountName: string; status: Array<number> }>};
}

type State = {
  dashboard: {
    status: Status
  }
}

function Status() {
  
  const dispatch = useDispatch()

  const {
    payload:statusPayload,
    isLoading:statusIsLoading
  }: Status = useSelector((state: State) => state?.dashboard?.status, shallowEqual);

  console.log('payload', statusPayload)

  useEffect(() => {
    dispatch(statusFetch());
  }, [])

  const { G2Astatus, GamivoStatus, DigisellerStatus, KinguinStatus } = useMemo(() => ({
    G2Astatus: (statusPayload && statusPayload.g2a) || [],
    GamivoStatus: (statusPayload && statusPayload.gamivo) || [],
    DigisellerStatus: (statusPayload && statusPayload.ggsel) || [],
    KinguinStatus: (statusPayload && statusPayload.kinguin) || [],
  }), [statusPayload]);

  return (
    <div className={s.statusLayout}>
   <SyncTable
        account="G2A"
        list={G2Astatus}
        loading={statusIsLoading}
    />
    <SyncTable
        account="GAMIVO"
        list={GamivoStatus}
        loading={statusIsLoading}
    />
    <SyncTable
        account="GGSEL"
        list={DigisellerStatus}
        loading={statusIsLoading}
    />
    <SyncTable
        account="Kinguin"
        list={KinguinStatus}
        loading={statusIsLoading}
    />
 </div>
 )
}

export default Status;
