import React, { useCallback } from 'react';
import { Select } from 'antd';
import { debounce } from 'lodash';
import { lookupGamivo } from '../../../../Reducers/dashboard';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import clearSpecialCharacters from '../../../../Utils/clearSpecialCharacters';
import LoadingOutlined from '@ant-design/icons/lib/icons/LoadingOutlined';
import { CreateMapping } from '../../../../types/rootState';

const { Option } = Select;

interface LookupState {
  dashboard: {
    lookGmvo: any
  }
}


const ProductCreateGamivo: React.FC<CreateMapping> = ({setMktProductId, setProductSlug }) => {

  const dispatch = useDispatch()
  const lookup = useSelector((state: LookupState) => state?.dashboard?.lookGmvo, shallowEqual);

  const onSearch = useCallback(
    (() => {
      const debouncedLookup = debounce((value) => {
        dispatch(lookupGamivo(value))
      }, 700);

      return (query: string) => {
        const cleared = clearSpecialCharacters(query);
        if (cleared.length >= 3) {
          debouncedLookup(cleared);
        }
      };
    })(),
    [],
  );

  const handleChange = (e: any) => {
    // console.log('e', e.split('_'))

    const values = e.split('_')
    setMktProductId(values[0])
    setProductSlug(values[1])
  };

return (
 
  <Select
  showSearch
  showArrow
  placeholder="Input search query"
  defaultActiveFirstOption={false}
  filterOption={false}
  onSearch={onSearch}
  onChange={handleChange}
  notFoundContent={
    !lookup.isLoading ? (
      <p>Empty list</p>
    ) : (
      <p>
        <LoadingOutlined />
      </p>
    )
  }
  loading={lookup.isLoading}
>
  {lookup && lookup?.payload?.map((item: any) => <Option value={`${item.id}_${item.slug}`} title={item.slug} key={item.id}>{item.name} ({item.slug})</Option>)}
</Select>
   
);

};

export default ProductCreateGamivo


