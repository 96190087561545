import ApiService from "./ApiService"
import { arena, common, core, gamivo, ggsel, steamprices, kinguin } from "./constants"

function preparedSteamLookup(arr, region) {
  const newArr = [];

  // eslint-disable-next-line camelcase
  arr.forEach(({ subId, name, app_type, prices }) => {
    newArr.push({
      subId,
      name,
      // app_type,
      price_initial: prices["DE"]?.priceEur || null,
      price_discount: prices["DE"]?.discountPriceEur || null,
      discount: prices["DE"]?.discount || null,
      eod: prices["DE"]?.eod || false,
    });
  });
  return newArr;
}

export function prepareChangeItem(params) {
  // params = data, query, filters
  let url = '';

  const { filters, query } = params;

  const obj = {
    filters,
    query,
  };

  if (obj.query) {
    url += `&query=${encodeURIComponent(obj.query)}`;
  }

  if (obj && obj.filters) {
    Object.keys(obj.filters).forEach((item) => {
      const value = obj.filters[item];
      if (value) {
        if (Array.isArray(value)) {
          value.forEach((element) => {
            url += `&${item}[]=${encodeURIComponent(element)}`;
          });
          return;
        }

        url += `&${item}=${encodeURIComponent(value)}`;
      }
    });
  }

  return url;
}

const DashboardService = {

  fetchStatus() {
    return ApiService.get(`/api/v3/status`);
  },

  fetchOrderedList({ filters, ...params }) {

    // console.log('FETCHPARAMS', params)
    // console.log('FETCHFILTERS', filters)

    const updatedParams = { ...params };

    if (filters && Object.keys(filters).length) {
      for (let key in filters) {
        updatedParams[key] = filters[key];
      }
    }

    return ApiService.get(`${core}/offers`, updatedParams);
  },

  fetchRegionsSteam() {
    return ApiService.get(`${core}/regions`);
  },

  fetchMarketsList() {
    return ApiService.get(`${core}/markets`);
  },

  createProduct(data) {
    return ApiService.post(`${common}/mapping`, [{ ...data, pltId: 1 }])
  },

  fetchComments(params) {

    const { pltId, productId, region } = params

    return ApiService.get(`${core}/product/${pltId}/${productId}/${region}/comments`)
  },

  addComment(params, comment) {
    const { pltId, productId, region } = params;

    const url = `${core}/product/${pltId}/${productId}/${region}/comments`;
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'text/plain',
      },
      body: comment,
    };

    // Get the authorization token from the ApiService
    const auth = ApiService.ts.getAuth();

    // Set the authorization header
    options.headers['Authorization'] = auth;

    // Make the request using fetch
    return fetch(ApiService.getApiLink(url), options);
  },

  gameLookup(q, sub) {
    return ApiService.get(`${steamprices}/sub/${sub}?r=${q}`)
  },

  steamLookup(query, region) {
    const preparedQuery = query.split(/\s+/).join(',');
    return ApiService.get(`${steamprices}/s`, {
      q: preparedQuery,
      r: region?.toLowerCase(),
    }).then((data) => preparedSteamLookup(data, region));
  },

  digiLookup(q) {
    return ApiService.get(`${ggsel}/products/ggsel`, { q })
  },

  platiMarketLookup(q) {
    return ApiService.get(`${ggsel}/products/plati`, { q })
  },

  // Create mappings
  g2aLookup(q) {
    return ApiService.get(`${arena}/steam/products`, { q })
  },

  gamivoLookup(q) {
    return ApiService.get(`${gamivo}/steam/products`, { q })
  },

  kinguinLookup(q) {
    return ApiService.get(`${kinguin}`, { q })
  },

  groupUpdate(data) {
    return ApiService.patch(`${core}/mapping`, data);
  },

  // Update products
  applyStatusToProducts(params) {
    const query = prepareChangeItem(params);

    const requestData = params.status

    return ApiService.patch(`${core}/mapping?multi=true${(query && `${query}`) || ''}`, requestData);
  },

  changeProfit(params) {
    const query = prepareChangeItem(params);

    const requestData = params.d;

    return ApiService.patch(`${core}/mapping?multi=true${(query && `${query}`) || ''}`, requestData);
  },

  setDefaultProfit(params) {
    const query = prepareChangeItem(params);
    const requestData = [{ setDefaultProfit: params.setDefaultProfit }];

    return ApiService.patch(`${core}/mapping?multi=true${(query && `${query}`) || ''}`, requestData);
  },

  setAction(params) {
    const { mktId, mktProductId, data } = params;

    const query = prepareChangeItem(params);

    return ApiService.patch(
      // `${core}/mapping`, [{ "mktId": mktId, "mktProductId": mktProductId, "isActive": value, "hasStock": stock }],
      `${core}/mapping`, [{ ...data, "mktId": mktId, "mktProductId": mktProductId }],
    );
  },

  steamUpdateProfit(params) {

    const { data } = params

    const query = prepareChangeItem(params);

    return ApiService.patch(`${core}/mapping`, data);
  },

  profitOverwriteToggle(params) {
    const { data } = params

    const query = prepareChangeItem(params);

    return ApiService.patch(`${core}/mapping`, [...data]);
  },

  setLockProduct(params) {
    const { mktId, mktProductId, value } = params;
    return ApiService.patch(
      `${core}/mapping`, [{ "mktId": mktId, "mktProductId": mktProductId, "enabled": value }],
    );
  },

  postGroupMapping(data) {
    return ApiService.post(`/api/v3/product/group`, { ...data, pltId: 2 })
  },

  deleteGroupMapping(data) {
    return ApiService.delete(`/api/v3/product/group`, { ...data, pltId: 2 })
  },

  deleteMapping(data) {
    return ApiService.delete(`/api/v3/mapping`, [{ ...data, pltId: 2 }])
  },

  updatePlatformProductId(data) {
    return ApiService.patch(`${core}/mapping`, data);
  },

  fetchSettings() {
    // data
    return ApiService.get(`/api/v3/configs`);

    // return new Promise((resolve) => {
    //   setTimeout(resolve, 1000, mockSettings);
    // });
  },

  fetchSettingsSchema() {
    return ApiService.get(`/api/v3/configs/schema`)
  },
  fetchSettingsUISchema() {
    return ApiService.get(`/api/v3/configs/uischema`)
  },
  updateSettings(data) {
    return ApiService.put(`/api/v3/configs`, data);
  },

}

export default DashboardService
