import { Popconfirm, Popover } from 'antd';
import React from 'react';
import QuestionCircleOutlined from '@ant-design/icons/lib/icons/QuestionCircleOutlined';
import DeleteOutlined from '@ant-design/icons/lib/icons/DeleteOutlined';


interface DeleteMapping {
  value?: boolean,
  id: number,
  handleDeleteMapping: any,
  mktId: number
};

const DeleteMapping: React.FC<DeleteMapping> = ({ value, id, mktId, handleDeleteMapping }) => {
  return (
    <Popconfirm
      title={
        <span>
          Do you really want to delete this mapping?
        </span>
      }
      icon={<QuestionCircleOutlined />}
      okText="Yes"
      cancelText="No"
      onConfirm={() => handleDeleteMapping(mktId, id)}
    >
      <Popover
        content={
          <span>
            Delete <b>{id}</b>
          </span>
        }
      >
          <DeleteOutlined style={{fontSize: '10px', color: 'red'}}/>
        
      </Popover>
    </Popconfirm>
  );
}

export default React.memo(DeleteMapping);
