//@ts-nocheck
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Popover } from 'antd';
import useBoolean from '../../Utils/useBoolean';
import EditOutlined from '@ant-design/icons/lib/icons/EditOutlined';
//@ts-ignore
import LoadingBlock from '../LoadingBlock';

interface PopoverEditable {
  children: any,
  changeBlock: void,
  handleSave: void,
  initialValue?: any,
  iconClassName?: string,
  isLoading: boolean,
  withTitle?: boolean,
  title?: string,
  iconStyle?: object,
  titlePopover?: string,
  popoverContentStyle?: object,
};

const PopoverEditable: React.FC<PopoverEditable> = ({
  children,
  changeBlock,
  handleSave,
  initialValue,
  isLoading,
  withTitle,
  title,
  titlePopover,
  popoverContentStyle,
}) => {
  const { value: showPopover, setFalse: closePopover, setValue: handleVisibleChange } = useBoolean(false);

  const inputRef = useRef(null);
  const [inputValue, handleChange] = useState(initialValue);

  const handleClosePopover = useCallback(() => {
    closePopover();
    handleChange(initialValue);
  }, [initialValue, closePopover]);

  useEffect(() => {
    closePopover();
    handleChange(initialValue);
  }, [initialValue, closePopover]);

  useEffect(() => {
    setTimeout(() => {
      if (showPopover && inputRef.current && inputRef.current.input) {
        inputRef.current.input.focus();
      }
    });
  }, [showPopover]);

  const content = (
    <LoadingBlock loading={isLoading}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {changeBlock({
          value: inputValue,
          handleChange: (v) => handleChange(v),
          inputRef,
        })}
        <div className="ml-small">
          <Button size="small" type="ghost" onClick={handleClosePopover}>
            Cancel
          </Button>
          <Button
            size="small"
            type="primary"
            onClick={() => handleSave(inputValue)}
            disabled={inputValue === initialValue}
            className="ml-small"
          >
            Save
          </Button>
        </div>
      </div>
    </LoadingBlock>
  );

  return (
    <Popover
      content={content}
      trigger="click"
      visible={showPopover}
      onVisibleChange={handleVisibleChange}
      title={withTitle ? title || 'Edit' : null}
    >
      <span style={{ cursor: 'pointer', position: 'relative', ...popoverContentStyle }}>
        <Popover content={titlePopover || 'Change profit'}>
          {children}
          <EditOutlined />
        </Popover>
      </span>
    </Popover>
  );
};

export default PopoverEditable;
