import qs from 'qs';
import frc from 'front-end-common';
import TokenService from './TokenService';
const {
  REACT_APP_AUTH_CLIENT_ID: CLIENT_ID,
  REACT_APP_AUTH_URL: AUTH_URL,
} = process.env;

const cognitoCredType = {
  CLIENT_ID,
  AUTH_URL,
}

export function prepareQueryString(params) {
  const updParams = Object.keys(params)
    .filter(key => params[key] !== null)
    .reduce((acc, cur) => ({ ...acc, [cur]: params[cur] }), {});

  return qs.stringify(updParams, { encode: false, arrayFormat: 'brackets' });
}

export function parseQueryString(queryString) {
  return qs.parse(queryString, { ignoreQueryPrefix: true });
}

const ApiService = new frc.services.ApiService(``, TokenService, cognitoCredType);
console.log('APISERVICE ====>', ApiService)

export default ApiService;
