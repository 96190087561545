const marketToString = (id, mktObj) => {
  let market

  for (let key in mktObj) {
    // eslint-disable-next-line no-unused-expressions
    mktObj[key] === id ? market = key : ''
  }

  return market
}

export default marketToString
