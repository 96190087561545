import { Col, Row, Tag, Tooltip } from 'antd'
import React from 'react';
import styles from './styles.module.scss'

interface StatTag {
  key: number,
  profit: number,
  profitPct: number,
  sold: number,
  profitAvg: number,
  percentage: number
}

function getRandomInt(max: number) {
  return +(Math.random() * max).toFixed(2);
}

// Достаем из ls текщую сортировку, 


const defineOrder = (value: string, soldVar, profitVar, profitPctVar) => {
  switch (value) {
    case 'sold':
      return soldVar
      break
    case 'profit':
      return profitVar
      break
    case 'profitPercent':
      return profitPctVar
      break
    default:
      return soldVar
  }
}

const StatisticsTag: React.FC<StatTag> = ({ profit, profitPct, sold, profitAvg }) => {


  // console.log('defineOrder', statisticSorter)

  return (
    
          <Row style={{padding: '24px 0'}} gutter={[2, 4]}>
                  <Col span={24}>
                  <Tooltip title="Average profit" placement="top">
                    <Tag color="default" className="stat-tag">
                    <small className={!profitAvg ? styles.nd : ''}>{profitAvg || 'n/d'}</small>
                    </Tag>
                    </Tooltip>
                  </Col>
                  <Col span={24}>
                  <Tooltip title="Profit percentage" placement="left" color="gold">
                    <Tag color={profitPct ? (profitPct < 15 ? (profitPct < 10 ? "#e25757" : "#d5b837") : "#87d068") : "default"} className="stat-tag">
                      <small className={!profitPct ? styles.nd : ''}>{profitPct ?  profitPct + '%' : 'n/d'}</small>
                    </Tag>
                    </Tooltip>
                  </Col>
                  <Col span={24}>
                    <Tooltip title="Profit" placement="left" color="blue">
                      <Tag color={profit ? "#2db7f5" : "default"} className="stat-tag" >
                        <small className={!profit ? styles.nd : ''}>{profit ? profit : 'n/d'}</small>
                      </Tag>
                    </Tooltip>
                  </Col>
                  <Col span={24}>
                  <Tooltip title="Sold" placement="bottom" color="green">
                    <Tag color={sold ? "#85d2f6" : "default"} className="stat-tag">
                      <small>{sold || 0}</small>
                    </Tag>
                    </Tooltip>
                  </Col>
            </Row>
          
  )
}

export default StatisticsTag
