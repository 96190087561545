// import Routes from '../routes';
import React from 'react'
import { Route, Routes } from "react-router-dom";
import RedirectPage from "../../Pages/RedirectPage";
import RedirectUserToMainPage from "../../Components/Redirect/ToMain";
import RedirectGuestToSignInPage from "../../Components/Redirect/ToSignIn";
import Dashboard from "../../Pages/Dashboard";
import DashboardSettings from "../../Pages/DashboardSettings";
import Status from "../../Pages/Status";

function App() {

  return (
  <div className='App'>
    <Routes>
      <Route path="/redirect" element={<RedirectPage />} />
      <Route path="/sign-in" element={<RedirectUserToMainPage />} />
      {/* <Route path="/dashboard-settings" element={<DashboardSettings/>} /> */}
      <Route path="/" element={<RedirectGuestToSignInPage />}>
        <Route
          path="/"
          element={<Dashboard />}
        />
        <Route
          path="/dashboard"
          element={<Dashboard />}
        />
        <Route path="/dashboard-settings" element={<DashboardSettings />} />
        <Route path="/status" element={<Status />} />
      </Route>
    </Routes>
  </div>
  )
}

export default App;
