//@ts-nocheck
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Drawer, Form, Button, Input, message, Divider, Tooltip, List, Spin } from 'antd';
import ClearOutlined from '@ant-design/icons/lib/icons/ClearOutlined';
import PlusOutlined from '@ant-design/icons/lib/icons/PlusOutlined';
import CommentOutlined from '@ant-design/icons/lib/icons/CommentOutlined';
import { addNewComment, commentsFetch } from '../../Reducers/dashboard';
import useBoolean from '../../Utils/useBoolean';
import { PrintDateTimeToHours } from '../Print/PrintDateTime';
import { AccountCircle } from '@mui/icons-material';
import s from './styles.module.scss';

const { TextArea } = Input;

interface CommentSection {
  region: string;
  productId: string;
  pltId: string;
  pltComment: string;
  afterUpdate: any;
  isLoading: boolean
}

const CommentSection: React.FC<CommentSection> = ({ pltId, productId, region, pltComment, afterUpdate, isLoading }) => {
  const dispatch = useDispatch();

  const [isProcessing, setIsProcessing] = useState(false);
  const [comment, setComment] = useState('');
  const [commentsList, setcommentsList] = useState();
  const [enableClearing, setEnableClearing] = useState(false);

  const { value: isOpen, setFalse: close, setTrue: open } = useBoolean(false);

  // console.log('pltComment', pltComment)
  useEffect(() => {
    if (!pltComment) setEnableClearing(true);
    else setEnableClearing(false)
  }, [pltComment]);

  const fetchComments = useCallback(() => {
    const params = { pltId, productId, region };
    dispatch(commentsFetch(params))
      .then((response) => {
        setcommentsList(response);
      })
      .catch((error) => {
        message.error(`Failed to get list of comments: ${error}`);
      });
  }, [dispatch, pltId, productId, region]);

  const addComment = useCallback(() => {
    const params = { pltId, productId, region };

    dispatch(addNewComment(params, comment))
      .then((res) => {
        !res.error && message.success('Comment has been added!');
        setComment('');
        afterUpdate();
        fetchComments();
      })
      .catch((e) => {
        message.error(`Failed to add comment: ${e}`);
        setIsProcessing(false);
      });
  }, [dispatch, pltId, productId, region, comment]);



  const cleanComment = useCallback(() => {
    const params = { pltId, productId, region };

    dispatch(addNewComment(params, ''))
      .then((res) => {
        !res.error && message.success('Comment has been cleaned!');
        setEnableClearing(true);
        fetchComments();
        afterUpdate();
      })
      .catch((e) => {
        message.error(`Failed to clean comment: ${e}`);
        setIsProcessing(false);
      });
  }, [dispatch, pltId, productId, region, comment]);



  // const handleClearClick = useCallback(() => {
  //   cleanComment();
  //   setIsClearingEnabled(true); // Enable clearing after it's clicked
  // }, [cleanComment]);

  return (
    <div>
      <Drawer open={isOpen} title="List of comments" height={800} closable placement="bottom" onClose={close}>
        {/* {isLoading ? (
          <Spin />
        ) : (  */}
        <>
        {commentsList ? (
          <List loading={isLoading} itemLayout="vertical">
            {commentsList?.comments?.map((comm, index) => {
              if (comm.text !== '') {
                return (
                  <div key={index} className={s.commentWrapper}>
                    <div className={s.user}>
                      <AccountCircle style={{ fontSize: '20px', marginRight: '4px', color: 'rgb(24, 144, 255)' }} />
                      {comm.author}
                    </div>
                    <div>{comm.text}</div>
                    <small>
                      <PrintDateTimeToHours className="fs-12" date={comm.ts} />
                    </small>
                    <Divider />
                  </div>
                );
              }
              return null;
            })}
          </List>
        ) : (
          <>
            <span>No comments yet...</span>
            <Divider />
          </>
        )}
</>
{/* )} */}
<>
        <TextArea placeholder="Type comment..." rows={2} style={{ marginBottom: '12px' }} value={comment} onChange={(e) => setComment(e.target.value)} />
        <Button type="primary" icon={<PlusOutlined />} onClick={addComment} style={{ marginRight: '12px' }} disabled={comment === ''}>
          Add comment
        </Button>
        <Button
          type="primary"
          icon={<ClearOutlined />}
          onClick={cleanComment}
          disabled={enableClearing} 
          loading={isLoading}
        >
          Clear
        </Button>
        </>
        
      </Drawer>
      <div className={s.drawerButtonWrapper}>
      
      <Button
      // style={{width: '90px'}}
      icon={<CommentOutlined style={{fontSize: '10px', width: '10px', marginRight: '8px', color: 'rgb(24, 144, 255)'}} />}
        type="text"
        onClick={() => {
          open();
          fetchComments(); 
        }}
      >
        <Tooltip placement="topLeft" title={`Click to ${pltComment ? 'see' : 'add'} comments`}>
          <small>{pltComment ? `${pltComment}` : 'Add comment'}</small>
        </Tooltip>
      </Button>
      
      </div>
    </div>
  );
};

export default React.memo(CommentSection);
