/* eslint-disable react/prop-types */
import React from 'react';
import RangeInput from '../../../Inputs/RangeInput';
import { Filter } from '../../../../types/rootState';

interface PriceRangeFilter extends Filter {
  min: number,
  max: number,
  label?: string | null,
}

const PriceRange: React.FC<PriceRangeFilter> = ({ onChange, value, min, max, label = null }) => {
  return <RangeInput onChange={onChange} value={value} min={min} max={max} label={label || 'Steam Price'} />;
}

export default PriceRange;
