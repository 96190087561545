import React, {useState, useCallback, useEffect, useMemo} from 'react';
import { Table, Button } from 'antd';
import s from './styles.module.scss';
import { LoadingOutlined, CheckCircleTwoTone, CaretRightOutlined, CaretDownOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import ColumnDescription from './Columns/ColumnDescription';
import ColumnNumber from './Columns/ColumnNumber';
import ColumnStartedAt from './Columns/ColumnStartedAt';
import ColumnCompletedAt from './Columns/ColumnCompletedAt';
import type { ColumnsType } from 'antd/es/table';

interface Sync {
  account: string,
  list: {[key: string]: Array<{ accountName: string; status: {accountName: string, completedAt: number, descr: string, errors: number, isCompleted: boolean, rejected: number, startedAt: number, succeed: number, total: number} }>}[],
  loading: boolean,
}

interface Records {
  accountName: string;
  status?: Array<{ [key: string]: any }>;
}

interface DataType {
  title: string,
  dataIndex: string,
  key: string,
  width: number,
  align: string,
  className?: undefined,
  record: Records,
  accountName: string,
  status: Array<{ [key: string]: any }>,
}

const SyncTable: React.FC<Sync> =({
                      account,
                      list,
                      loading,
                    }) => {



  const GetColumns = useCallback((): ColumnsType<DataType> => {
    return [
      {
        title: 'Name',
        dataIndex: 'accountName',
        key: 'accountName',
        width: 50,
        align: 'center',
        render: (_, record) => {
          return <div>{record.accountName}</div>
        },
      },
      {
        title: 'Description',
        dataIndex: 'status[0].descr',
        key: 'status[0].descr',
        width: 90,
        align: 'center',
        className: 'inner-rows',
        render: (_, record) => {
          const status = record.status && record.status
          return <>
          {  
            status.map((item, index) => {
              return <div key={index} className={s.innerRowContent}>
                <ColumnDescription isCompleted={item?.isCompleted} descr={item?.descr} />
               </div>
              }
            )
          }
          </>
        },
      },
      {
        title: 'Total',
        dataIndex: 'total',
        key: 'total',
        width: 50,
        align: 'center',
        className: 'inner-rows',
        render: (_, record) => {
          const status = record.status && record.status
          return <div>
          {  
            status.map((item, index) => {
              return <div key={index} className={s.innerRowContent}>
             <ColumnNumber quantity={item.total} />
             </div>
        })
          }
          </div>
        }, 
      },
      {
        title: 'Succeed',
        dataIndex: 'succeed',
        key: 'succeed',
        width: 75,
        align: 'center',
        className: 'inner-rows',
        render: (_, record) => {
          const status = record.status && record.status
          return <div>
          {  
            status.map((item, index) => {
              return <div key={index} className={s.innerRowContent}>
            <ColumnNumber quantity={item.succeed} />
            </div>
        })
          }
          </div>
        }, 
      },
      {
        title: 'Errors',
        dataIndex: 'errors',
        key: 'errors',
        width: 60,
        align: 'center',
        className: 'inner-rows',
        render: (_, record) => {
          const status = record.status && record.status
          return <div>
          {  
            status.map((item, index) => {
              return <div key={index} className={s.innerRowContent}>
            <ColumnNumber quantity={item.errors} />
            </div>
        })
          }
          </div>
        }, 
      },
      {
        title: 'Started at',
        dataIndex: 'startedAt',
        key: 'startedAt',
        width: 84,        
        align: 'center',
        className: 'inner-rows',
        render: (_, record) => {
          const status = record.status && record.status
          return <div>
          {  
            status.map((item, index) => {
              return <div key={index} className={s.innerRowContent}>
            <ColumnStartedAt time={item.startedAt} />
            </div>
        })
          }
          </div>
        }, 
      },
      {
        title: 'Completed at',
        dataIndex: 'completedAt',
        key: 'completedAt',
        width: 108,
        align: 'center',
        className: 'inner-rows',
        render: (_, record) => {
          const status = record.status && record.status
          return <div>
          {  
            status.map((item, index) => {
              return <div key={index} className={s.innerRowContent}>
                <ColumnCompletedAt completed={item.completedAt} />
                </div>
        })
          }
          </div>
        }, 
      },
    ]; 
  }, [loading, list])

  const [columns, setColumns] = useState(GetColumns());

  useEffect(() => {
      setColumns(GetColumns())
  }, [list, loading, GetColumns])

  let status = list && !!list.find((item) => item.status.find((el: any) => el.isCompleted === false))

  const pagination = useMemo(() => ({
    pageSize: 1,
  }), []);

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  );

  const [isOpen, setOpen] = useState(false);
  const toggleOpen = () => setOpen(!isOpen);
  
  return (
    <>
      {!isOpen && (
        <Button type="link" onClick={() => toggleOpen()} style={{ padding: 0, display: "block" }}>
           <div>
                <CaretRightOutlined />
                <span style={{marginLeft: '5px', marginRight: '10px'}}>Show sync status with {account}</span>
                {
                  status
                  ? <Spin indicator={antIcon} />
                  : <CheckCircleTwoTone twoToneColor="#52c41a" />
                }
              </div>
        </Button>
      )}
      {isOpen && (
        <>
            <Button type="link" onClick={() => toggleOpen()} style={{ padding: 0 }}>
            <div>
                <CaretDownOutlined />
                <span style={{marginLeft: '5px', marginRight: '10px'}}>Close sync status with {account}</span>
                {
                  status
                  ? <Spin indicator={antIcon} />
                  : <CheckCircleTwoTone twoToneColor="#52c41a" />
                }
              </div>
            </Button>
            <Table
              className={s.syncTable}
              bordered
              dataSource={list}
              // scroll={{ x: "max-content" }}
              size="middle"
              loading={loading}
              // @ts-ignore
              columns={columns}
              pagination={pagination}
              rowKey={(record) => `${record.status.map((row) => row.accountName)}`}
              // rowKey={(record) => `${record.accountName}`}
            />
        </>
      )}
    </>
  );
}

export default SyncTable;
