//@ts-nocheck
import React, { useEffect, useMemo, useState } from 'react'
import s from './styles.module.scss'
import FiltersBlock from '../../Components/FiltersBlock'
import CreateProduct from '../../Components/CreateProduct'
import EditIsActive from '../../Components/Controls/EditIsActive'
import EditProfit from '../../Components/Controls/EditProfit'
import ResetProfit from '../../Components/Controls/ResetProfit'
import { useDispatch, useSelector } from 'react-redux'
import { fetchRegions, listFetch } from '../../Reducers/dashboard'
import useTable from '../../Utils/useTable'
import ProductsTable from '../../Components/ProductsTable'
import DashboardConfig from '../../Components/DashboardConfig'

const Dashboard = (props) => {

  const { list, fetch, filters, query, onSearch, onFilter, pagination, onChange } = useTable(state => state.dashboard.productsList, listFetch)
  const dispatch = useDispatch();

  const [selectedRow, onSelectChange] = useState([]);
  const [isConfigProcessing, setIsConfigProcessing] = useState(false);

  const { payload, isLoading } = useSelector((state) => state?.dashboard?.productsList) || {}

  console.log('payload', payload)

  //@ts-ignore
  const regions = useSelector((state) => state.dashboard.regionsSteam);
  console.log('regions', regions)
  useEffect(() => {
    if (!regions.payload) {
      dispatch(fetchRegions());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    // list, 
    total 
  } = useMemo(
    () => ({
      // list: (payload && payload.page) || [],
      total: (payload && payload.total) || [],
    }),
    [payload]
  )

  const finalCountRecords = useMemo(() => (selectedRow.length ? selectedRow.length : total), [selectedRow, total]);

  console.log('release 1.0.0')

  return (
    <>
    <div className={s.buttonsGroup}>
        <DashboardConfig setIsConfigProcessing={setIsConfigProcessing} isConfigProcessing={isConfigProcessing} />
        <FiltersBlock {...props} onFilter={onFilter} query={query} onSearch={onSearch} filters={filters} viewButton={false} />
        <CreateProduct list={list} afterUpdate={fetch} />
        <EditIsActive filters={filters} query={query} selected={selectedRow} finalCountRecords={finalCountRecords} afterUpdate={fetch} />
        <EditProfit filters={filters} query={query} selected={selectedRow} finalCountRecords={finalCountRecords} afterUpdate={fetch} />
        <ResetProfit filters={filters} query={query} selected={selectedRow} finalCountRecords={finalCountRecords} afterUpdate={fetch} />
        <div style={{marginTop: '7px', marginRight: '24px'}}>
        for <b>{finalCountRecords || 0}</b> {finalCountRecords === 1 ? 'game' : 'games'}
        </div>
    </div>

        <ProductsTable
        // tableOnChange={onChange}
        list={list}
        isLoading={isLoading}
        afterUpdate={fetch}
        filter={filters}
        query={query}
        pagination={pagination}
        // selected={selectedRow}
        selectedRowKeys={selectedRow}
        onChange={onChange}
        onSelectChange={onSelectChange}
        onFilter={onFilter}
        />
    </>
  )
}

export default Dashboard
