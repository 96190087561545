import React from 'react';
import { Filter, Filters } from '../../../../types/rootState';
import RangeInput from '../../../Inputs/RangeInput';

interface CurrentProfitRangeProps extends Filter {
  min: number,
  max: number
}

const CurrentProfitRange: React.FC<CurrentProfitRangeProps> = ({ onChange, value, min, max }) => {
  return (
  <RangeInput
    onChange={onChange}
    min={min}
    max={max}
    value={value}
    label="Current Profit"
  />
  )
}

export default CurrentProfitRange;
