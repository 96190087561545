import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import useBoolean from '../../../Utils/useBoolean';
import {Button, message, Popconfirm} from 'antd';
import { UndoOutlined } from '@ant-design/icons';
import s from './styles.module.scss'
import { groupSetDefaultProfit, groupUpdateProducts } from '../../../Reducers/dashboard';
import { Filters } from '../../../types/rootState';
import splitArray from '../../../Utils/splitArray';

interface GroupChange {
  afterUpdate: any,
  selected: string[],
  finalCountRecords: number,
  filters: Filters,
  query: string
}

type SetDefault = {
  setDefault: [string]
}

const ResetProfit: React.FC<GroupChange> = ({
                     afterUpdate,
                     selected,
                     finalCountRecords,
                     filters,
                     query
                   }) => {

  const dispatch = useDispatch();

  const { value: isOpen, setFalse: close, setTrue: open } = useBoolean(false);

  const handleSuccess = useCallback((data: SetDefault) => {

    if (selected.length) {
    
    const newArr = selected.map((el) => el.split(','))
    const preparedArr = splitArray(newArr)
    
    const handleData = preparedArr.map((item) => {
      return {...item, ...data}
    })
    //@ts-ignore
    dispatch(groupUpdateProducts(handleData)).then(() => {
      message.success('Actions have been changed!');
      afterUpdate();
    }).catch((error: string) => {
      message.error(`Can\'t edit actions: ${error}`);
    });
    close();
  }

    if (!selected.length) {
      const paramsData = {
        setDefaultProfit: true,
        filters,
        query,
      }
      //@ts-ignore
      dispatch(groupSetDefaultProfit(paramsData)).then(() => {
        message.success('Actions have been changed!');
        afterUpdate();
      }).catch((error: string) => {
        message.error(`Can\'t edit actions: ${error}`);
      });
      close();

    }
  }, [dispatch, afterUpdate, close, selected]);
  
  return (
    <>
    <Popconfirm
        title={`Are you sure to set default profit for ${finalCountRecords} ${finalCountRecords === 1 ? 'product' : 'products'}?`}
        onConfirm={() => handleSuccess({'setDefault': ["profit"]})}
        okText="Yes"
        cancelText="No"
      >
        <Button
          type="primary"
          disabled={!finalCountRecords}
          icon={<UndoOutlined />}
          className={s.changeRatios}
        >
          Set default profit
        </Button>
      </Popconfirm>
    </>
  );
};

export default React.memo(ResetProfit);
