import React, { useCallback } from 'react';
import { Button, InputNumber, message, Slider } from 'antd';
import cx from '../../../Utils/cx';
import useBoolean from '../../../Utils/useBoolean';
import useDebounce from '../../../Utils/useDebounce';
import s from './styles.module.scss';
import { PriceChangeTwoTone } from '@mui/icons-material';

interface RangeInputProps {
  label: string,
  max: number,
  min: number,
  value?: number[],
  onChange: any,
  className?: string,
};

const RangeInput: React.FC<RangeInputProps> = ({ label, min, max, value, onChange }) => {
  const { value: inputMode, toggleValue: toggleInputMode } = useBoolean(true);

  const { value: isFocused, setFalse: onBlur, setTrue: onFocus } = useBoolean(false);

  const handleChange = useCallback(
    (value: [number, number]) => {
      const [minValue, maxValue] = value;
      let start = min;
      let stop = max;

      if (minValue > min && minValue <= max) {
        start = minValue;
      }

      if (maxValue >= min && maxValue < max) {
        stop = maxValue;
      }

      onChange([start, stop]);
    },
    [min, max, onChange],
  );

  useDebounce(
    (value = [min, max], handleChange: any) => {
      const [minValue, maxValue] = value;
      if (typeof minValue !== 'number' || typeof maxValue !== 'number') return;

      if (minValue > maxValue) {
        if (isFocused) {
          handleChange([minValue, minValue]);
          message.error('Invalid max value! Changing max value in the filter ...');
        } else {
          handleChange([maxValue, maxValue]);
          message.error('Invalid min value! Changing min value in the filter ...');
        }
      }
    },
    500,
    value,
    handleChange,
  );

  return (
    <div className={cx(s.RangeInput, s.className)}>
      <div className={s.label}>
      <PriceChangeTwoTone style={{fontSize: '12px', marginRight: '4px', color: 'rgb(24, 144, 255)'}} />
        <b>{label}</b>
        <Button type="link" size="small" onClick={toggleInputMode} className={s.viewMode}>
          <small>view mode</small>
        </Button>
      </div>
      <div className={s.container}>
        {inputMode ? (
          <>
            <InputNumber
              min={min}
              max={max}
              size="small"
              onFocus={onFocus}
              onBlur={onBlur}
              className={s.input}
              value={value?.[0]}
              onChange={(e) => handleChange([e as number, value?.[1] as number])}
            />
            <span className="mr-small ml-small">-</span>
            <InputNumber
              max={max}
              min={min}
              size="small"
              className={s.input}
              value={value?.[1]}
              onChange={(e) => handleChange([value?.[0] as number, e as number])}
            />
          </>
        ) : (
          <>
            <span className="mr-small">{min}</span>
            {/* @ts-ignore */}
            <Slider range className={s.range} value={value} onChange={handleChange} min={min} max={max} />
            <span className="ml-small">{max}</span>
          </>
        )}
      </div>
    </div>
  );
};

export default RangeInput;
