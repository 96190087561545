import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import SignIn from '../../../Pages/SignIn';

function RedirectUserToMainPage() {
  let location = useLocation();
  //@ts-ignore
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated, shallowEqual);

  if (location.pathname === '/sign-out') {
    location.pathname = '/';
  }
  return !isAuthenticated ? <SignIn /> : <Navigate to={location.pathname  || '/'} />

}

export default RedirectUserToMainPage;
